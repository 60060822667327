









import { Component, Vue } from "vue-property-decorator";
import NavSection from "./components/_Nav.vue";
import SiteTemplate from "./components/_Template.vue";

@Component({
  components: {
    NavSection,
    SiteTemplate,
  },
})
export default class App extends Vue {}
