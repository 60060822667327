































/*
Note: search for ut_word_rotator for the js script of rotatorWord
ut_rotator_words used to be fixed array of strings.
 */
import { contentfulHtml, slug } from "@/utils";
declare const jQuery: any;
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      bgHome: (url: string) => {
        return `background: url("${url}");`;
      },
    };
  },
  methods: {
    contentfulHtml: contentfulHtml,
    slug: slug,
    scrollTo: (href: string) => {
      jQuery.scrollTo( href , 650, { easing: 'swing' , offset: -80 , 'axis':'y' } );			
    }
  },
};
