














import Home from "./Home.vue";
import About from "./About.vue";
import Team from "./Team.vue";
import Service from "./Service.vue";
import Portfolio from './Portfolio.vue';
import Testimonial from './Testimonial.vue';
import Contact from './Contact.vue';
import Footer1 from './Footer1.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
export default {
  computed: {
    components() {
      return window.content.body;
    },
    tempComponents() {
      return [
        // { type: "home" },
        // { type: "about" },
        { type: "team" },
        { type: "service" },
        { type: "portfolio" },
        { type: "testimonial" },
        { type: "contact" },
      ];
    },
  },
  components: {
    Home,
    About,
    Team,
    Service,
    Portfolio,
    Testimonial,
    Contact,
    Footer1,
  },
};
